import { CdkStepper } from "@angular/cdk/stepper";
import { Component, inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { CreateSalesOrderService } from "@data/services/create-sales-order.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { STEP } from "@shared/components/atlas/stepper/types";
import { buildFirstStepFormGroupSalesOrder } from "./forms";
import { CompanyService } from "@data/services/company.service";
import { Company } from "@data/schemas/company/company";

@Component({
	selector: "app-criar-pedido-de-venda",
	templateUrl: "./criar-pedido-de-venda.component.html",
	styleUrls: ["./criar-pedido-de-venda.component.scss"]
})
export class CriarPedidoDeVendaComponent implements OnInit, OnDestroy {
	private readonly bsModalRef = inject(BsModalRef);
	private readonly createSalesOrderService = inject(CreateSalesOrderService);
	private readonly companySevice = inject(CompanyService);

	@ViewChild("stepper") private stepper!: CdkStepper;

	protected formStepOne = buildFirstStepFormGroupSalesOrder();

	ngOnInit(): void {
		this.createSalesOrderService.doSomeRequest().subscribe();
	}

	ngOnDestroy(): void {
		this.createSalesOrderService.emitDestroySalesOrder(true);
	}

	private updateFormValidity(form: FormGroup): void {
		Object.keys(form.controls).forEach((key) => {
			const control = form.get(key);
			control?.markAsTouched();
			control?.markAsDirty();
			control?.updateValueAndValidity();
		});
	}

	private fireStepOne(): void {
		const createOrUpdateCompanyRequest =
			this.formStepOne.value.accountType === 0
				? this.companySevice.updateCompanyAndCreateSalesOrder(
						this.formStepOne.value as Partial<Company>
					)
				: this.companySevice.createCompanyAndSalesOrder(
						this.formStepOne.value as Partial<Company>
					);
		createOrUpdateCompanyRequest.subscribe();
	}

	private fireActualStep(step: number): void {
		if (step === STEP.ONE) {
			this.updateFormValidity(this.formStepOne);
			if (this.formStepOne.valid) {
				this.fireStepOne();
			}
		}
	}

	protected closeModal(): void {
		this.bsModalRef.hide();
	}

	protected goToNextStep(): void {
		const actualIndex = this.stepper.selectedIndex;
		this.fireActualStep(actualIndex);
	}
}
