import {
	ENV_CONFIG,
	EnvironmentConfig
} from "@core/environments/environment-config";
import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AbTestChosen } from "@data/schemas/ab-test-chosen";
import { Observable } from "rxjs";
import { AuthService } from "@core/services/auth/auth.service";
import { AbTestVariant } from "@data/schemas/ab-test-variant";

@Injectable({
	providedIn: "root"
})
export class AbTestService {
	constructor(
		protected http: HttpClient,
		private authService: AuthService,
		@Inject(ENV_CONFIG) private config: EnvironmentConfig
	) {}

	private readonly AB_TESTS_ENDPOINT = "ab-tests";
	private readonly API_BFF_URL = this.config.environment.API_BFF;

	findAbTestByName(name: string, id: string): Observable<AbTestVariant> {
		const params = new HttpParams()
			.set("name", name)
			.set("distinctId", id)
			.set(
				"hashedFingerprint",
				this.authService.getHashedFingerprint() ?? ""
			);
		return this.http.get<AbTestVariant>(
			`${this.API_BFF_URL}/${this.AB_TESTS_ENDPOINT}/by-name`,
			{ params, withCredentials: true }
		);
	}

	findAbTestByDistinctId(id: number): Observable<AbTestChosen> {
		const params = new HttpParams()
			.set("distinctId", id.toString())
			.set(
				"hashedFingerprint",
				this.authService.getHashedFingerprint() ?? ""
			);
		return this.http.get<AbTestChosen>(
			`${this.API_BFF_URL}/${this.AB_TESTS_ENDPOINT}/by-distinctid`,
			{ params, withCredentials: true }
		);
	}
}
