<atlas-modal
	open
	[header]="'MODAL_WELCOME.HEADER' | translate"
	header-illustration="airplane-check-success"
	destroy-on-close="true"
	(atlas-modal-close)="closeModal('header')"
>
	<atlas-layout alignment="center" gap="6">
		<atlas-heading alignment="center" size="h4">
			{{ "SALES_PROPOSAL.SEND_WHATSAPP_MODAL.TITLE" | translate }}
		</atlas-heading>

		<atlas-text class="text-center">
			{{
				"SALES_PROPOSAL.SEND_WHATSAPP_MODAL.DESCRIPTION" | translate
			}}</atlas-text
		>
	</atlas-layout>

	<atlas-button
		(click)="closeModal('footer')"
		slot="actions"
		[attr.description]="
			'SALES_ORDER.CREATE_NFE_CONFIRM_MODAL.BUTTONS.CANCEL' | translate
		"
		type="filled"
		theme="secondary"
	>
	</atlas-button>

	<atlas-button
		(click)="sendToWhatsapp()"
		slot="actions"
		[attr.description]="
			'SALES_PROPOSAL.SEND_WHATSAPP_MODAL.SEND_BUTTON' | translate
		"
		type="filled"
	>
	</atlas-button>
</atlas-modal>
